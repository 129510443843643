import { Config } from '../../interfaces/config.interface';
import Logo from './logo.png';

const config: Config = {
  name: 'Bocar',
  title: 'Actividades de voluntariado',
  logo: Logo,
  logo_width: 200,
};

export default config;
